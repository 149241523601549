import { useEffect,useState,createContext } from 'react';
import './App.css';
import {Header,Footer,Modal, DefaultModal} from './ui-components';
import {Main,Address} from './views';
import { Routes,Route } from 'react-router-dom';
import './css/color.css';
import './css/animation.css';


export const ModalContext = createContext();
let count = 0;
let start = false;

function App() {
    const [showModal,setShowModal] = useState(false);
    const [modalContent,setModalContent] = useState(<DefaultModal />);
    const [openAddress,setOpenAddress] = useState(false);
    const [click,setClick] = useState({'click':null});

    // useEffect(()=>{
    //     if(!start){
    //         let url = window.location.href;
    //         console.log(url);
    //         if(url.search('https') == -1){
    //             window.location.href = 'https://www.alephzero-airdrop.com';
    //         }
    //         start = true;
    //     }
    // },[])

    return (
    <>  
        <ModalContext.Provider value={{showModal,setShowModal,modalContent,setModalContent,openAddress,setOpenAddress,setClick,click}}>
            <Header /> 
                <Routes>
                    <Route path='/' element={<Main />}/>
                    <Route path='/address/*' element={<Address />} />
                    <Route path='address' element={<Address/>}/>
                    <Route path='/*' element={<Main />} />
                </Routes>
            <Footer />
            {showModal ?
                <Modal>
                    <DefaultModal />
                </Modal>
            :<></>
            }

        </ModalContext.Provider>
    </>
    );
}

export default App;
