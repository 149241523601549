import React,{useContext, useEffect,useState} from 'react'
import css from '../css/header.module.css';
import smallLogo from '../images/azero-small.jpeg';
import logo from '../images/logo.svg';
import { Link,useLocation } from 'react-router-dom';
import { ModalContext } from '../App';

const Header = () => {
  const [showBtn,setShowBtn] = useState(false);
  const {click} = useContext(ModalContext);
  const location = useLocation();
  
  // useEffect(()=>{
  //   console.log(click);
  // },[click]);
  useEffect(()=>{
    const path = location.pathname;
    if(path == '/'){
       setShowBtn(true);
    }
    else{
      setShowBtn(false);
    }
  },[location])
  return (
    <>
      <header>
       
            <aside className={css.headerAside}>
                <Link to='/'>
                    <img src={smallLogo} alt='alt' width='30' height='auto' />
                    <p>
                    Mainnet validators are coming to Aleph Zero in December, 2022
                    </p>
                </Link>
                <span className={css.span1}></span>
                <span className={css.span2}></span>                
            </aside>
            <nav>
              <Link to='/'>
                <img src={logo} />
              </Link>
              {
                showBtn?
                 <button onClick={()=>click.click()}>
                  CLAIM AIRDROP
                </button>
                :<></>
              }
             
            </nav>

      </header>
      
    </>
    
  )
}

export default Header