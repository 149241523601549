import React,{useState,useEffect} from 'react'
import css from '../css/modal.module.css';


const Modal = ({children}) => {
  return (
    <div className={css.modal}>
        <div></div>
        <div className={css.modalContent}>
            {children}
        </div>
    </div>
  )
}

export default Modal;

const gif  = './images/loading.gif';
export const DefaultModal = () =>{
    return (
        
        <div className={css.defaultDiv}>
            <div>Please wait ...</div>
            <div>
                <img src={gif} className={css.loading}/>
            </div>
            <div></div>
        </div>
    );
}
