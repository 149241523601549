import React,{useState,useEffect,useContext,useRef} from 'react'
// import vid from '../images/vid.mp4';
import gif from '../images/gif.gif';
import css from '../css/main.module.css';
import ecosystem from '../images/ecosystem.png';
import { ModalContext } from '../App';
import { useNavigate } from 'react-router-dom';


const svg1 = './images/1.svg';
const svg2 = './images/2.svg';
const svg3 = './images/3.svg';
const svg4 = './images/4.svg';
const svg5 = './images/5.svg';
const svg7 = './images/7.svg';
const svg8 = './images/8.svg';
const img1 = './images/1.png';
const img2 = './images/2.png';
const img3 = './images/3.png';
const img4 = './images/4.png';
const img5 = './images/5.png';
const img6 = './images/6.png';
const img7 = './images/7.png';
const img8 = './images/8.png';
const vid = './images/vid.mp4';

let start = false;
let counter = 0;
const stop = 5;
let timer = null;
const amountArr = [
  1000,2000,5000,10000,15000,20000,30000,40000
]

const Main = () => {
  const [airdrop,setAirdrop] = useState('1000');
  const [bonus,setBonus] = useState('3000.00');
  const {setShowModal,setOpenAddress,setClick} = useContext(ModalContext);
  const navigate = useNavigate();
  const howRef = useRef(null);
  const airdropRef = useRef(null);
  const compRef = useRef(null);

  const generateAddress = () =>{
     setShowModal(true);
     timer = setInterval(()=>{
        counter++;
        if(counter === stop){
          clearInterval(timer);
          counter = 0;
          setOpenAddress(true);
          navigate('/address');
          setShowModal(false);
        }
     },1000)

  }

  const handleScroll = (element)=>{
    if(element && element.current){
        element.current.scrollIntoView({behavior: "smooth"});
    }
  }
  const scrollToComp = () =>{
    handleScroll(compRef);
  }

  const handleAirdrop = (amt,ref)=>{
     setAirdrop(amt.toString());
     if(ref && ref.current){
        ref.current.classList.add('activeAmount');
     }
  }

  useEffect(()=>{
    if(airdrop && !isNaN(Number(airdrop))){
        setBonus(`${(3 * Number(airdrop).toFixed(2))}`)
    }
    setClick({'click':scrollToComp})
    //console.log({'click':scrollToComp})
    
  },[])

  useEffect(()=>{
    const scrollElements = document.querySelectorAll(".ani");
    scrollElements.forEach((el) => {
        el.style.opacity = 0
    })
    const elementInView = (el, percentageScroll = 100) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
          elementTop <= 
          ((window.innerHeight || document.documentElement.clientHeight) * (percentageScroll/100))
        );
      };
    
    const displayScrollElement = (element) => {
        let name = element.className;
        let ind = name.split("-")[1]
        if(ind.search('scrolled') == -1){
    
        }
        else{
            ind = ind.split(" ")[0];
        }
        element.style.opacity = 1
        element.classList.add(`scrolled-${ind}`);
      };
    
    const hideScrollElement = (element) => {
        let name = element.className;
        let ind = name.split("-")[1]
        if(ind.search('scrolled') == -1){
    
        }
        else{
            ind = ind.split(" ")[0];
        }
        element.classList.remove(`scrolled-${ind}`);
        element.style.opacity = 0;
      };   
    
    const handleScrollAnimation = () => {
        scrollElements.forEach((el) => {
          if (elementInView(el, 100)) {
            displayScrollElement(el);
          } else {
            hideScrollElement(el);
          }
        })
    }
    handleScrollAnimation();
    window.addEventListener('scroll',handleScrollAnimation);    
    return ()=> window.removeEventListener('scroll',handleScrollAnimation);

},[])

useEffect(()=>{
  if(airdrop){
    let allAmountBtn = document.querySelectorAll('.amtBtn');
    allAmountBtn.forEach(amt =>{
       let amtVal = amt.textContent;
       if(amtVal == Number(airdrop)){
          amt.classList.add('activeAmount');
       }
       else{
        amt.classList.remove('activeAmount')
       }
       if(isNaN(Number(airdrop))){
         setBonus('0.00')
       }
       else{
        setBonus((Number(airdrop) * 3).toFixed(2).toString());
       }
       

    })
  }else{
    setBonus('0.00');

  }
},[airdrop])
  return (
    <main>
      <section className={`${css.banner} ani ani-1`}>
          <video 
            disablePictureInPicture
            controlsList="nodownload"
            width="320"
            height="240" src={vid} autoPlay 
            control="" playsInline="" 
            muted="muted" loop preload="auto"
            poster={gif}
          >
            {/* <source src={vid} type="video/mp4" />
            Your browser does not support the video tag. */}
          </video>
          <span></span>
      </section>

      <section ref={compRef} className={`${css.scrollDiv} ani ani-2`}>
            <h1 className={`${css.title}`}>FEATURE IN</h1>
            <div className={css.scroll}>
                <div>
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src={img8} alt='alt' />
                  <img src={svg1} alt='alt' />
                  <img src={svg2} alt='alt' />
                  <img src={svg3} alt='alt' />
                  <img src={svg4} alt='alt' />
                  <img src={svg5} alt='alt' />
                  <img src={svg7} alt='alt' />
                  <img src={svg8} alt='alt' />
                  <img src={img1} alt='alt' />
                  <img src={img2} alt='alt' />
                  <img src={img3} alt='alt' />
                  <img src={img4} alt='alt' />
                  <img src={img5} alt='alt' />
                  <img src={img6} alt='alt' />
                  <img src={img7} alt='alt' />
                  <img src='../../public/images/8.png' alt='alt' />
                </div>
            </div>
            <div className={css.competition}>
                <div>
                    <span>AIRDROP LIVE</span>
                    <div>AlephZero Airdrop Competition</div>
                    <p>
                      To commemorate the progress made by Azero blockchain and the advancement of the AlephZero community, we have committed a total of 100,000,000 $AZERO airdrop to users worldwide. Every person can participate. In order to be eligible, participants should have a minimum of 2000 $AZERO during the competition period. All Qualifying users will split a pool of 100,000,000 $AZERO
                    </p>
                    <p>
                        <button onClick={()=>handleScroll(howRef)}>How to Join Competition</button>
                    </p>
                </div>
                <div>
                    <img src={ecosystem} />

                </div>
            </div>
      </section>

      <section className={`${css.how} ani ani-2`} ref={howRef}>
        <div>
          <span>Join Airdrop</span>
          <div>STEPS TO PARTICIPATE IN ALEPHZERO 3X AZERO CHALLENGE</div>
          <div>
            <div>
                <div>
                  To participate, enter the amount of $AZERO you want to send and specify your receiving address, you can make the transaction using any Wallet or Exchange
                </div>
                <div>
                  Once your transaction is received, an outgoing transaction will be sent to your recieving address, Chargeback time from 30 seconds to 24 hours.
                </div>
                <div>
                  You can participate in the competition until all allocated $AZERO tokens for airdrop are completely distributed
                </div>
                <div>
                  All users can ONLY participate and claim airdrop once. Don't forget to invite your friends!
                </div>
            </div>
              
            </div>
        </div>
            
      </section>

      <section className={`${css.airdrop} ani ani-3`} ref={airdropRef}>
        <div className={css.selectDiv}>
            <div>
              <span onClick={()=>handleAirdrop(amountArr[0])} className='amtBtn activeAmount'>{amountArr[0]}</span>
              <span onClick={()=>handleAirdrop(amountArr[1])} className='amtBtn'>{amountArr[1]}</span>
              <span onClick={()=>handleAirdrop(amountArr[2])} className='amtBtn'>{amountArr[2]}</span>
              <span onClick={()=>handleAirdrop(amountArr[3])} className='amtBtn'>{amountArr[3]}</span>
              <span onClick={()=>handleAirdrop(amountArr[4])} className='amtBtn'>{amountArr[4]}</span>
              <span onClick={()=>handleAirdrop(amountArr[5])} className='amtBtn'>{amountArr[5]}</span>
              <span onClick={()=>handleAirdrop(amountArr[6])} className='amtBtn'>{amountArr[6]}</span>
              <span onClick={()=>handleAirdrop(amountArr[7])} className='amtBtn'>{amountArr[7]}</span>
            </div>
        </div>
        < div className={css.inputDiv}>
            <div>
              <div>
                <span>Stake</span>
                <input type='text' placeholder='enter stake' value={airdrop} onChange={(e)=>setAirdrop(e.target.value)} />
              </div>
            <div>
              <span>Bonus</span>  
              <input text='text' id='bonus' value={bonus} disabled />
            </div>
          </div>
          <div>
            <p>Enter your sending or receiving address</p>
            <div>
              <span>Address</span>
              <input type='text' />			
            </div>
            <p>
              <button onClick={()=>generateAddress()}>GENERATE ADDRESS</button>
            </p>
          </div>
        </div>
      </section>
        
    </main>
  )
}

export default Main