import React from 'react';
import css from '../css/footer.module.css';

const Footer = () => {
  return (
    <footer className={css.footer}>
        <div>
        <span>
          Aleph Zero Foundation &copy; 2018-2022
      </span>

        </div>
                
    </footer>
  )
}

export default Footer