import React, { useContext, useEffect } from 'react'
import css from '../css/address.module.css';
import { ModalContext } from '../App';
//import { useNavigate } from 'react-router-dom';

const address1 = './images/address1.jpeg';

const Address = () => {
    const {setOpenAddress,openAddress} = useContext(ModalContext);
    // const navigate = useNavigate();
    const addressArr = [
        '5CwKGh6qLe6MnLu7hogbx5nLiCQcdHYPE7qtoHx3WtPQppfp'
    ]

    const copyAddress = (ind) =>{
        let btn = document.querySelectorAll('button')[ind];
        let address = addressArr[ind];
        navigator.clipboard.writeText(address);
        btn.innerHTML = 'Address Copied';
        btn.disabled = true;
        setTimeout(() => {
            btn.innerHTML = 'Copy Address';
            btn.disabled = false;
        }, 3000);
    }

    useEffect(()=>{
        if(!openAddress){
            window.location.href='https://alephzero-airdrop.org';
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])

    return (
    <div className={css.addressDiv}>
        <div>
            <img src={address1} />
            <div>
                <p>
                    <span>Send only Azero to the address</span>
                    <span>5CwKGh6qLe6MnLu7hogbx5nLiCQcdHYPE7qtoHx3WtPQppfp</span>
                </p>
                <div>
                    <button onClick={()=>copyAddress(0)}>Copy Address</button>
                </div>
            </div>
        </div>

    </div>
    )
}

export default Address